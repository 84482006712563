import axios from 'axios';
// 创建 axios 实例
const service = axios.create({
  baseURL: '/api',
  // withCredentials: true,
  timeout: 60000
});

// 请求拦截器
service.interceptors.request.use(config => {
  return config;
}, error => {
  return Promise.reject(error);
});

// 响应拦截器
service.interceptors.response.use(response => {
  return response;
}, error => {
  return Promise.reject(error);
});

export default service;