<template>
  <HelloWorld msg="Welcome to Your Vue.js App"/>
</template>

<script>
import HelloWorld from './components/HelloWorld.vue'

export default {
  name: 'App',
  components: {
    HelloWorld
  }
}
</script>

<style>
html,body{
  background-color: #F7EED6;
  box-sizing: border-box;
}
*{
  margin: 0;
  padding: 0;
}
</style>
