<template>
  <div class="container">
    <div class="container_top">
   <!--    <div class="top_one_text"></div>
      <div class="top_two_text">演 出<span class="first">·</span>票 务<span class="first">·</span>明 星  周 边</div> -->
    </div>
    <div class="list">
      <img class="listimg" src="../assets/image/list.png">
    </div>
    <div class="container_next">
      <div :class="next_content">
        <div class="top_img" v-show="topShow">
          <!-- <img class="topImg" src="../assets/image/tophome.png"> -->
        </div>
        <div :class="next_text">
          <div v-show="showResult">
            <img class="lineImg" src="../assets/image/line.png">
            <p class="p1 p_c">派大星发现有许多旁门左派开启了山寨模式</p>
            <p class="p2 p_c">套用派大星的头像和朋友圈</p>
            <p class="p3 p_c">以免各位宝宝被山寨欺骗</p>
            <p class="p4 p_c">派大星开启了验真伪模式!</p>
            <p class="p5">只需在输入框输入派大星个人微信</p>
            <div class="btn_color"></div>
            <p class="p6">一秒辨别真假</p>
            <div class="btn_color1"></div>
            <div class="jt">
              <img class="jtImg" src="../assets/image/sc.png">
              <img class="jtImg" src="../assets/image/sc.png">
              <img class="jtImg" src="../assets/image/sc.png">
              <img class="jtImg" src="../assets/image/sc.png">
              <img class="jtImg" src="../assets/image/sc.png">
              <img class="jtImg" src="../assets/image/sc.png">
              <img class="jtImg" src="../assets/image/sc.png">
              <img class="jtImg" src="../assets/image/sc.png">
              <img class="jtImg" src="../assets/image/sc.png">
            </div>
            <p class="p7">输入时，一定要避免空格 !!!</p>
            <div class="user_text">
              <input class="input_t" v-model="num" style="text-align: center;" type="text" placeholder="请输入微信号/电话号码">
            </div>
            <div class="user_text1" @click="handleSelect()">查 询</div>
          </div>

          <div style="width:100%; height: 100%;position: relative;" v-show="showResult1">  <!--  -->
            <!-- <div class="sure_text_1">真!</div> -->
            <div class="sure_textnext4">查询结果如下!</div>
            <div class="sure_textnext5">您所查询到的是派大星票务所授权的正规代理，</div>
            <!-- <div class="sure_textnext6">您可以放心购买交易!</div> -->
            <div class="line"></div>
            <!-- <div class="sure_img1"><img style="width: 100%;height: 100%;" src='../assets/image/yes.png' /></div> -->
            
          

            <!-- <div class="sure_text_text11" >手机端长按图片下载</div> -->
            <img class="right_img1" :src="image_url" />

           

            <div class="sure_text_text5" onclick="location.reload();">返回代理查询页</div>
          </div>


          <div v-show="showResult2"> <!--  -->
            <div class="sure_text_1">假!</div>
            <div class="line"></div>
            <div class="sure_img"><img style="width: 100%;height: 100%;" src='../assets/image/danger.png' /></div>
            <!-- <div class="sure_text_text">所有微信号经由派大星数据库匹配</div> -->
            <div class="sure_textnext_j">您 所 查 询 的 微 信 号</div>
            <div class="sure_textnext_j1"><span class="nospam">不 属 于</span>  <span class="span">派大星团队</span></div>
            <div class="sure_textnext_j2">请 不 要 转 账!</div>
            <div class="sure_textnext_j3">如 有 财 产 损 失 请 立 即 报 警 处 理!</div>
            <img class="right_img" src="../assets/image/pcry.jpg" />
          </div>

        </div>
      </div>
    </div>
  </div>
</template>

<script setup> 
import { ref } from 'vue';
import { tableData } from '../network/api'
const num = ref('')
const image_url = ref('')
const next_text = ref('next_text1')
const next_content = ref('next_content1')


const showResult = ref(true)
const showResult1 = ref(false)
const showResult2 = ref(false)

const topShow = ref(true)
const handleSelect = async() => {
  try {
    const { data: res } = await tableData({
      phone:num.value
     
    })

  

   
    if(res.data == null){
      showResult.value = false
      showResult1.value = false
      showResult2.value = true
      topShow.value = false
    }
    if(res.data !== null){
      showResult.value = false
      showResult1.value = true
      showResult2.value = false
      topShow.value = false
      image_url.value = res.data.url

      next_content.value = 'next_content'
      next_text.value = 'next_text'

      
    }
  } catch (error) {
    console.log(error)
  }

 
 
}



</script>

<!-- Add "scoped" attribute to limit CSS to this component only 
  const handleback = ()=> {
  showResult.value = true
  showResult1.value = false
  showResult2.value = false
  topShow.value = true
  num.value = ''
}

-->
<style scoped>
.container{
  width: 100%;
  height: 100%;
  box-sizing: border-box;
}
.container_top{
  width: 100%;
  height: 120px;
  box-sizing: border-box;
  z-index: 9;
  background-image: url('../assets/image/pdx.jpg');
  background-repeat: no-repeat;
  background-size: 100% 100%;
}
.top_one_text{
  width: 100%;
  height: 70px;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: url('../assets/image/pdx.jpg');
  background-repeat: no-repeat;
  background-size: 100% 100%;
}
.top_two_text{
  width: 100%;
  height: 40px;
  box-sizing: border-box;
  color: white;
  font-size: 18px;
  font-weight: 700;
  text-align: center;
  line-height: 40px;
  background-color: #bd3635;
}
.list{
  width: 100%;
  height: 40px;
  box-sizing: border-box;
}
.listimg{
  width: 100%;
  height: 40px;
}
.first{
  font-size: 14px;
  margin-left: 10px;
  margin-right: 10px;
}
.container_next{
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
}
.next_content{
  width: 320px;
  height: 660px;
  background-image: url('../assets/image/bgimg.png');
  background-repeat: no-repeat;
  background-size: 100% 100%;
  margin-top: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.container_next1{
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
}
.next_content1{
  width: 320px;
  height: 460px;
  background-image: url('../assets/image/bgimg.png');
  background-repeat: no-repeat;
  background-size: 100% 100%;
  margin-top: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}
.next_text1{
  width: 300px;
  height: 430px;
  border-radius: 20px;
  background-color: white;
  position: relative;
}

.top_img{
  width: 100px;
  height: 90px;
  background-repeat: no-repeat;
  background-size: 90% 100%;
  background-position: center;
  position: absolute;
  top: -35px;
  left: 35%;
  z-index: 9;
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: url('../assets/image/tophome.png');
}
.topImg{
  width: 70px;
  height: 70px;
}
.next_text{
  width: 300px;
  height: 630px;
  border-radius: 20px;
  background-color: white;
  position: relative;
}
.lineImg{
  width: 60px;
  height: 40px;
  position: absolute;
  right: 5px;
  top: 38px;
}
.p1{
  top: 50px;
  left: 25px;
}
.p2{
  top: 70px;
  left: 70px;
}
.p3{
  top: 95px;
  left: 70px;
}
.p4{
  top: 120px;
  left: 75px;
}
.p5{
  position: absolute;
  top: 145px;
  left: 32px;
  color: black;
  font-weight: 500;
  font-size:17px;
  z-index: 5;
}
.p6{
  position: absolute;
  top: 180px;
  left: 85px;
  color: black;
  font-weight: 500;
  font-size:17px;
  z-index: 5;
}
.p7{
  position: absolute;
  bottom: 125px;
  left: 54px;
  color: rgb(50, 109, 218);
  font-weight: 500;
  font-size:16px;
  z-index: 5;
}
.p_c{
  font-size: 14px;
  color: rgb(65, 63, 63);
  text-align: center;
  position: absolute;
}
.btn_color{
  width: 220px;
  height: 14px;
  background-color: rgb(217, 227, 245);
  position: absolute;
  top: 160px;
  left: 40px;
  border-radius: 20px;
}
.btn_color1{
  width: 80px;
  height: 14px;
  background-color: rgb(217, 227, 245);
  position: absolute;
  top: 195px;
  left: 100px;
  border-radius: 20px;
}
.jt{
  width: 60px;
  height: 60px;
  position: absolute;
  top: 215px;
  left: 114px;
  display: flex;
  flex-wrap: wrap;
}
.jtImg{
  width: 15px;
  height: 15px;
  margin-right: 5px;
}
.user_text{
  width: 220px;
  height: 40px;
  position: absolute;
  bottom: 75px;
  left: 40px;
}
.user_text1{
  width: 220px;
  height: 40px;
  position: absolute;
  bottom: 20px;
  left: 40px;
  color: white;
  font-size: 16px;
  text-align: center;
  line-height: 40px;
  border-radius:30px;
  background-color: rgb(39, 133, 240);
  box-shadow: 0px 3px 3px 0px rgb(2, 66, 139);
}
.input_t{
  width: 100%;
  height: 100%;
  border-radius: 30px;
  border: 1px solid #cccc;
}
.input_t::-webkit-input-placeholder{
  text-align: center;
  font-size: 15px;
}
.input_t:focus{
  outline: none;
}
.sure_text_1{
  width: 200px;
  height: 105px;
  position: absolute;
  top: 20px;
  left: 50px;
  font-weight: 700;
  font-size: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.line{
  width: 90%;
  height: 2px;
  border-bottom: 3px dashed #ccc;
  position: absolute;
  left: 15px;
  top: 125px;
}
.right_img{
  width: 100px;
  height: 100px;
  position: absolute;
  bottom: 1px;
  right: 1px;
  opacity: 0.6;
}
.right_img1{
  width: 90%;
 
  position: absolute;
  top: 180px;
  left: 50%;
  transform: translateX(-50%);
}
.sure_img1{
  width: 60px;
  height: 60px;
  position: absolute;
  top: 140px;
  left: 50%;
  margin-left: -30px;
  display: flex;
  justify-content: center;
}
.sure_img{
  width: 60px;
  height: 50px;
  position: absolute;
  top: 140px;
  left: 50%;
  margin-left: -30px;
  display: flex;
  justify-content: center;
}
.sure_text_text{
  background-color: rgb(215, 213, 213);
  width: 80%;
  margin: 0 auto;
  height: 20px;
  position: absolute;
  top: 145px;
  left: 50%;
  transform: translateX(-50%);
  font-size: 12px;
  color: #171616;
  text-align: center;
  line-height: 20px;
  border: 1px solid #ccc;;
}

.sure_text_text11{
  /* background-color: rgb(215, 213, 213); */
  width: 80%;
  margin: 0 auto;
  height: 20px;
  position: absolute;
  top: 145px;
  left: 50%;
  transform: translateX(-50%);
  font-size: 12px;
  color: #171616;
  text-align: center;
  line-height: 20px;
  /* border: 1px solid #ccc;; */
}
.sure_textnext4{
  width: 100%;
  height: 30px;
  position: absolute;
  font-size: 14px;
  color: black;
  font-weight: bold;
  text-align: center;
  line-height: 30px;
}
.sure_text_text5{
  position: absolute;
  font-size: 12px;
  color: black;

  text-align: center;
  line-height: 30px;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
}
.sure_textnext5{
  width: 100%;
  height: 30px;
  position: absolute;
  top: 40px;
  font-size: 14px;
  color: black;
  font-weight: bold;
  text-align: center;
  line-height: 30px;
}
.sure_textnext6{
  width: 100%;
  height: 30px;
  position: absolute;
  top: 80px;
  font-size: 14px;
  color: black;
  font-weight: bold;
  text-align: center;
  line-height: 30px;
}
.sure_textnext1{
  width: 100%;
  height: 30px;
  position: absolute;
  top: 240px;
  font-size: 16px;
  color: black;
  font-weight: bold;
  text-align: center;
  line-height: 30px;
}
.sure_textnext2{
  width: 100%;
  height: 30px;
  position: absolute;
  top: 275px;
  font-size: 12px;
  color: black;
  font-weight: bold;
  text-align: center;
  line-height: 30px;
  font-size: 16px;
}
.span{
  font-weight: bold;
  color: black;
  font-size: 17px;
}
.sure_textnext3{
  width: 100%;
  height: 30px;
  position: absolute;
  font-weight: bold;
  top: 310px;
  font-size: 16px;
  color: black;
  text-align: center;
  line-height: 30px;
}

.sure_textnext_j{
  width: 100%;
  height: 30px;
  position: absolute;
  top: 220px;
  font-size: 16px;
  color: black;
  font-weight: bold;
  text-align: center;
  line-height: 30px;
}
.sure_textnext_j1{
  width: 100%;
  height: 30px;
  position: absolute;
  top: 250px;
  font-size: 12px;
  color: black;
  font-weight: bold;
  text-align: center;
  line-height: 30px;
  font-size: 16px;
}
.nospam{
  font-weight: bold;
  color: red;
  font-size: 17px;
}
.span{
  font-weight: bold;
  color: black;
  font-size: 17px;
}
.sure_textnext_j2{
  width: 100%;
  height: 30px;
  position: absolute;
  font-weight: bold;
  top: 280px;
  font-size: 16px;
  color: red;
  text-align: center;
  line-height: 30px;
}
.sure_textnext_j3{
  width: 100%;
  height: 30px;
  position: absolute;
  font-weight: bold;
  top: 308px;
  font-size: 16px;
  color: red;
  text-align: center;
  line-height: 30px;
  z-index: 999;
}

.sure_btn{
  width: 200px;
  height: 40px;
  background-color: rgb(84, 139, 241);
  position: absolute;
  top: 40px;
  left: 50px;
  border-radius: 20px;
  font-size: 16px;
  text-align: center;
  line-height: 40px;
  color: white;
}
.sure_text{
  width: 200px;
  height: 140px;
  position: absolute;
  top: 100px;
  left: 50px;
  font-weight: 700;
  font-size: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.user_avatar{
  width: 200px;
  height: 40px;
  position: absolute;
  bottom: 115px;
  left: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.yImg{
  width:40px;
  height: 40px;
  margin-right: 10px;
}
.yes{
  position: absolute;
  bottom: 80px;
  left: 70px;
  color: black;
}
.yes1{
  position: absolute;
  bottom: 90px;
  left: 70px;
  color: black;
}
.jg{
  position: absolute;
  bottom: 57px;
  left: 74px;
  color: red;
}
.back_btn{
  width: 200px;
  height: 40px;
  background-color: rgb(84, 139, 241);
  position: absolute;
  bottom: 20px;
  left: 50px;
  border-radius: 20px;
  font-size: 16px;
  text-align: center;
  line-height: 40px;
  color: white;
}
.back_btn1{
  width: 200px;
  height: 40px;
  background-color: rgb(84, 139, 241);
  position: absolute;
  bottom: 10px;
  left: 50px;
  border-radius: 20px;
  font-size: 16px;
  text-align: center;
  line-height: 40px;
  color: white;
}
</style>
